import React from "react";
import Home from "./home";
import Legal from "./legal";
//import {
//  BrowserRouter as Router,
//  Switch,
//  Route
//} from "react-router-dom";
import { Router } from "@reach/router";

const IndexPage = ({data}) => (
  <Router>
    <Legal path="legal" />
    <Home path="/" />
  </Router>
);

export default IndexPage;
